<template lang="pug">
app-layout
  .page-faq
    .t-h1-mobile.mb-6 Часто задаваемые вопросы о сервисе Portal DA

    router-link.faq-card.mb-4(
      v-for="section in faqSections"
      :key="section.id"
      :to="selectPath(section.id)"
    )
      .faq-card__title {{ section.title }}
      .t-normal.c-text(v-html="section.description")
      .faq-card__btn
        ui-btn(third width=32)
          ui-icon(name="arrow-right")

    .t-h2-mobile.mb-6.mt-10 Часто задаваемые вопросы
    faq-questions-list(:questions="this.featuredQuestions")
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import { UiIcon, UiBtn } from '@/uikit';
  import AppLayout from '@/layouts/AppLayout';
  import FaqQuestionsList from '@/components/FaqQuestionsList';
  import { service as API } from '@/services/pages';

  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageFAQ', 'blue');

  export default {
    name: 'faq',
    components: {
      UiIcon,
      UiBtn,
      AppLayout,
      FaqQuestionsList,
    },

    data: () => ({
      loading: true,
      featuredQuestions: [],
    }),

    computed: {
      ...mapGetters(['faqSections']),
    },

    mounted() {
      if (!this.faqSections || !this.featuredQuestions) this.fetchFaq();
    },

    methods: {
      async fetchFaq() {
        try {
          this.loading = true;
          const { sections, featured } = await API.faq();

          this.featuredQuestions = featured.questions;
          this.setFaqSections(sections);
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      selectPath(name) {
        if (name === 'feeds') {
          return '/feeds/autoload';
        } else {
          return this.$path.faqSection(name);
        }
      },
      ...mapActions(['setFaqSections']),
    },
  };
</script>

<style lang="scss">
  .page-faq {
    padding: 32px 16px;
  }

  .faq-card {
    display: block;
    box-shadow: shadow('xsmall', 'light');
    border-radius: 4px;
    padding: 24px;
    background: c('white', 'light');
    text-decoration: none;

    &__title {
      @include text-style('h2-mobile', 'light');
      color: c('black', 'light');
      margin-bottom: 12px;
    }

    &__btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
</style>
